import * as React from 'react';

const IdeaSvg = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={33.062} height={51.675} {...props}>
    <g fill='none' stroke='#fdb74e' strokeLinecap='round' strokeLinejoin='round' strokeWidth={1.5} data-name='Group 39752'>
      <path d='M8.843 46.879h0a4.047 4.047 0 0 0 4.046 4.046h4.046a4.047 4.047 0 0 0 4.047-4.046h0' data-name='Path 46484' />
      <g data-name='Group 39751'>
        <path d='M15.75.75v2' data-name='Line 1131' />
        <path d='m29.005 7.82 1.19-1.144' data-name='Line 1132' />
        <path d='m22.725 3.785.644-1.495' data-name='Line 1133' />
        <path d='M7.909 3.785 7.265 2.29' data-name='Line 1134' />
      </g>
      <path
        d='M8.843 40.404a15.027 15.027 0 0 0-1.916-5.039C3.988 31.502.751 26.765.751 21.79a14.567 14.567 0 1 1 29.134 0l2.428 7.283h-4.046v4.856a3.237 3.237 0 0 1-3.237 3.237h-1.342a2.428 2.428 0 0 0-2.355 1.839h0a11.706 11.706 0 0 0-.35 2.84v1.8H8.843'
        data-name='Path 46485'
      />
      <path d='M12.889 35.548c0-4.856 0-9.711-3.237-12.948h11.33c-3.237 3.237-3.237 8.093-3.237 12.948' data-name='Path 46486' />
    </g>
  </svg>
);
export default IdeaSvg;
